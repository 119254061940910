//cn
export default {
    language: {
        selectLanguage: '语言选择',
        zh: '中文',
        sp: 'Español',
        confirm: '确定'
    },
    cancel: '取消',
    confirm: '确定',
    querenDel:'确定删除全部/选中的内容',
    checkMore: '查看更多',
    delete: '删除',
    finish: '完成',
    nextStep: '下一步',
    submit: '提交',
    login: {
        // 登录
        phoneLogin: '手机号登录',
        emailLogin: '邮箱号登录',
        inputPhone: '请输入手机号',
        inputEmail: '请输入邮箱号',
        inputPassword: '请输入密码',

        login: '登录',
        registerAccount: '注册账号',
        forgetPassword: '忘记密码',
        quickLogin: '快捷登录',
        // 注册
        phoneRegister: '手机号注册',
        emailRegister: '邮箱号注册',
        inputCode: '请输入验证码',
        getCode: '获取验证码',
        sendAgain: 's后重新发送',
        emailSendAgain: '10分钟后重新发送',
        regiser: '注册',
        existAccount: '已有账号，前去',
        tickRepresentsAgree: '勾选即代表我同意',
        userRegistrationAgreement: '用户注册协议',
        and: '与',
        privacyPolicy: '隐私政策',
        userAgreement: '用户注册协议',
        // 忘记密码
        phoneFind: '手机号找回',
        emailFind: '邮箱号找回',
    },
    home: {
        address: '地址',
        allType: '全部分类',
        selectCity: '选择城市',
        city: '城市',
        price:'价格',
        minPrice:'最低价格',
        maxPrice:'最高价格',
        clearChoose:'清除筛选条件',
        gong:'共',
        tiao:'条数据',
        pleaseEnterKeyword: '请输入关键字',
        collect: '收藏',
        hasCollect: '已收藏',
        contact: '联系Ta',
        releaseTime: '发布时间',
        pictureDisplay: '图片展示',
        projectSchedule: '项目进度',
        cartSchedule: '购车进度',
        carShow: '车源展示',
        priceNegotiation: '价格商谈',
        deal: '成交',
        drawing: '图纸',
        InBuilding: '建筑中',
        presale: '预售',
        finish: '完工',
        about:'关于',
        specification:'规格',
        benefit:'好处',
        model:'模块',



        detailDesc: '详细描述',
        allComment: '全部评论',
        viewComments: '查看评论',
        confirmToDeleteHistory: '确定删除历史记录？',
        noSearchRecordsExist: '暂无搜素记录',
        selectAddress: '选择地区',
        selectType: '选择类型',
        price: '价格',
        reset: '重置',
        report: '举报',
        reportContent: '举报内容',
        uploadPhoto: '上传图片',
        provideMoreInformation: '提供更多信息有助于举报被快速处理',
        atMost: '最多',
        open: '张',
        informTips: '您的举报我们会在24小时内进行反馈，届时会以系统消息反馈到您的账号中',
        submit: '提交',
        send: '发送',
        inputComment: '请输入评论信息',
        share: '分享到',
        share2: '分享',
        commentText: '不可以评论自己发布的信息',
        search:'搜索',
        searchResult:'搜索结果',
        InformationClassification:'信息分类',
        newLouPan:'新楼盘',
        latestRelease:'最新发布',
        realEstate:'房地产',
        shopCenter:'商业中心',
        car:'车辆',
        newList:'最新上传',
        realEstateCompany:'房地产公司',
        usedCar:'二手车',
        ultimosNewOnline:'Ultimos/新上线',
        consecionaFranchisees:'Conseciona/特许经营商',
        hotBroker:'热门经纪人'



    },
    release: {
        title: '标题',
        inputTitle: '请输入标题',
        type: '类别',
        selectType: '请选择类别',
        cityLive: '所在城市',
        selectCity: '请选择城市',
        pleaseEnterDescriptionDetails: '请输入详情的描述',
        pleaseSelect: '请选择',
        pleaseInput: '请输入',
        pleaseSelectRegion: '请选择区域',
        pleaseInputInfo: '请填写信息',
        haveReadAndAgree: '我已阅读并同意',
        PanamaAgreement: '巴拿马信息发布协议',
        immediatelyRelease: '立即发布',
        submitSuccess: '提交成功',
        changeSuccess: '修改成功',
        waitPatient: '请您耐心等待平台审核信息，预计审核时间2-8个小时',
        backHomepage: '返回首页',
        changeInfo:'修改信息',
        placeholderYoushi:'请输入优势并用英文逗号隔开',
        total:'总计费用',
        JinWei:'是否竞位'

    },
    my: {
        release: '我的发布',
        openMembershipExpires: '开通会员免费发布',
        remainingNumber: '剩余条数',
        membershipDue: '会员到期',
        checkIn: '审核中',
        checkFailure: '审核失败',
        checkSuccess: '审核成功',
        headPortrait: '头像',
        Id: 'Id',
        nickName: '昵称',
        inputNickName: '请输入昵称',
        sex: '性别',
        private: '保密',
        birth: '生日',
        save: '保存',
        selectSex: '选择性别',
        selectBirth: '选择生日',
        youHaveNoCurrentMembership: '您当前未开通会员',
        validity: '有效期',
        openMembershipToEnjoyMoreBenefits: '开通会员享更多优惠',
        memberFreeReleaseExclusivePrivileges: '会员免费发布，专属特权',
        exclusiveMembershipLogoRenewableExtension: '专属会员标识，可续费延长',
        ordinaryPackage: '普通套餐',
        vipPackage: '会员套餐',
        vipSelect:'请选择会员套餐',
        rightMember: '会员权益',
        shopVip:'购买即代表同意',
        shopXieyi:'会员权益协议',
        buyNow: '立即购买',
        immediatelyRenewal: '立即续费',
        weiXinPay: '微信支付',
        paypalPay: "Paypal支付",
        applePay: '苹果支付',
        payNow: '立即支付',
        selectPayWay: '请选择支付方式',
        paySuccess: '支付成功',
        payWay: '支付方式',
        payTime: '支付时间',
        checkMember: '查看会员',
        applyIn: '审核中',
        expired:'已过期',
        applyFailure: '审核失败',
        failureReason: '失败原因',
        submitAgain: '重新提交',
        applySuccess: '审核成功',
        releaseTime: '发布于',

        edit: '编辑',
        finish: '完成',
        selectAll: '全选',
        tips: '提示',
        deleteAllContent: '确定删除当前内容？',

    },
    set: {
        editPassword: '修改密码',
        bindingPhone: '绑定手机号',
        editPhone: '修改/绑定手机号',
        bindingEmail: '修改/绑定邮箱',
        editEmail: '修改绑定邮箱',
        languageSettings: '语言设置',
        adviceFeedback: '意见反馈',
        Historical_feedback:'历史反馈',
        aboutUs: '关于我们',
        classifyText: '全部分类',
        cancelAccount: '注销账号',
        registrationProtocol: '用户注册协议',
        privacyAgreement: '隐私协议',
        clearCache: '清除缓存',
        checkUpdates: '版本更新',
        exitLogin: '退出登录',
        confirmClearCache: '确定清除缓存吗？',
        confirmExitLogin: '确定退出登录吗？',

        oldPasswordAuthentication: '旧密码验证修改',
        phoneAuthentication: '修改密码',
        emailAuthentication: '邮箱号验证修改',
        oldPassword: '旧密码',
        inputOldPassword: '请输入旧密码',
        newPassword: '新密码',
        inputNewPassword: '请输入新密码',
        confirmPassword: '确认密码',
        inputConfirmPasswordAgain: '请再次输入新密码',
        wantToVerifyMobile: '我们要对您绑定的手机号进行验证',
        wantToVerifyEmail: '我们要对您绑定的邮箱号进行验证',

        newEmail: '新邮箱',
        inputNewEmail: '请输入新邮箱',
        newPhone: '新手机号',
        inputNewPhone: '请输入新手机号',
        smsVerification: '短信验证',
        inputCode: '请输入验证码',
        getCode: '获取验证码',
        second: '秒',

        feedbackRecord: '反馈记录',
        feedbackType: '反馈类型',
        feedbackContent: '反馈内容',
        feedbackContentCannotEmpty: '反馈内容不能为空',
        feedbackContentTextarea: '问题描述的越详细，有助于我们更快的解决问题',
        uploadPhoto: '请上传应用截图，最多上传6张',
        leaveYourContactInformation: '请留下您的联系方式',
        phoneAndQQAndEmail: '手机号',
        contactWay: '联系方式',
        feedbackType: '反馈类型',
        feedbackTime: '反馈时间',
        platformReply: '平台回复',
        currentVersion: '当前版本',
        confirmClearCache: '确定清除缓存吗？',
        exitLogin: '退出登录',
        fillInTheCancellationApplication: '填写注销申请',
        accountCancel: '账号注销',
        permanentCancellation: '永久注销且无法恢复，请谨慎操作',
        selectCancelReason: '请选择注销账号的原因：',
        fillInTheCancelReason: "请填写注销原因",
        pleaseEnter: '请输入',
        accountCancelGiveUpRights: '账号注销后将放弃以下权益：',
        cancellationInstructions: '注销说明',
        clickTheButtonBelowToIndicateThatYouHaveReadIt: '点击下方按钮，即表示你已阅读并了解以上风险：',
        clearedTheDecksConfirmGoOn: '我已清楚风险，确定继续',
        back: '返回',
        pleaseClearedTheDecksConfirmGoOnAgain: '请再次确认，你已了解注销风险，并要继续执行该操作',
        cancellationApplicationSuccessful: '注销申请提交成功',
        waitPatientlyForPlatformReview: '请您耐心等待平台审核信息，预计审核时间1-3个工作日',
        beingProcessed: '处理中',
        cancellationReason: '注销理由',
        phoneText: '手机号格式不正确'
    },
    title: {
        forgetPassword: "忘记密码",
        bindingAccout: '绑定账号',
        systemMessages: '系统消息',
        platformNotice: '平台公告',
        messageDetail: '消息详情',
        systemDetail: '系统详情',

        classify: '分类',
        detail: '详情',
        allComments: '全部评论',
        hotRecommand: '热门推荐',

        myRelease: '我的发布',
        wantToRelease: '我要发布',
        personCenter: '个人中心',
        memberCenter: '会员中心',
        immediatePayment: '立即支付',
        payResult: '支付结果',
        myProfile: '我的资料',
        myCollect: '我的收藏',
        set: '设置',
        editPassword: '修改密码',
        editBindingPhone: '修改绑定手机号',
        editBindingEmail: '修改绑定邮箱',
        iNeedFeedback: '我要反馈',
        feedbackRecord: '反馈记录',
        aboutUs: '关于我们',
        cancelAccount: '注销账号',
        registrationProtocol: '注册协议',
        privacyPolicy: '隐私政策',
        helpCenter: '帮助中心',
        phoneService: '电话服务',
        advertiseDetail: '广告详情',
        inform: '举报',
        orderDetail: '发布详情',
        personInfor: '个人信息',
        companyInfor: '公司信息',
    },
    tips: {
        mobilePhoneNumberCannotBeEmpty: '手机号不能为空',
        emailNumberCannotBeEmpty: '邮箱号不能为空',
        codeCannotBeEmpty: '验证码不能为空',
        passwordCannotBeEmpty: '密码不能为空',
        pleaseRightPassword: '请输入正确的密码（6~12位字母+数字）',
        pleaseSelectUserProtocol: '请勾选用户协议',
        onlyOneVideoCanBeUploaded: '只能上传一个视频',
        pleaseEnterTitle: '请输入标题',
        pleaseEnterSelectionCategory: '请输入选择类别',
        pleaseSeleciCity: '请选择城市',
        pleaseEnterDetailedDescription: '请输入详细的描述',
        atLeastOnePhoto: '至少上传一张图片',
        pleaseCheckThePanamaInformationReleaseAgreement: '请勾选巴拿马信息发布协议',
        cannotEmpty: '不能为空',
        searchContent: '搜索内容不能为空',
        commentsCannotBeEmpty: '评论内容不能为空',
        theReportContentCannotBeEmpty: '举报内容不能为空',
        photoCannotBeEmpty: '图片不能为空',
        searchContentCannotBeEmpty: '搜索内容不能为空',
        pleaseCheckAtLeastOneItem: '请至少勾选一项',
        theTypeCannotBeEmpty: '类型不能为空',
        pleaseFillInTheReasonForCancellation: '请填写注销原因',


        // 需要翻译的
        validateUserEmail: '请输入正确的邮箱号',
        oldPasswordCannotEmpty: '旧密码不能为空',
        newPasswordCannotEmpty: '新密码不能为空',
        confirmPasswordCannotEmpty: '确认密码不能为空',
        theTwoPasswordsAreInconsistent: '两次密码输入的不一致',
        WeChatRegular: '请输入正确的微信号',

    },

    other: {
        backResult: '返回结果',
        share:'分享至',
        collect:'收藏',
        report:'举报',

        selectCity: '选择地区',
        selectType: '选择类型',
        price: '价格',
        fromTheHigh: '从高到低',
        fromBottomToTop: '从低到高',
        wechatFriends: '微信好友',
        circleOfFriends: '朋友圈',
        inform: '举报',
        myCollection: '我的收藏',
        myMessage: '我的消息',
        set: '设置',
        helpCenter: '帮助中心',
        all: '全部',
        applyIn: '审核中',
        applyFailure: '审核失败',
        applySuccess: '审核成功',
        uploadPhoto: '上传图片',
        uploadVideo: '上传视频',
        bottom: '到底啦',

        man: '男',
        women: '女',
        sendIn: '发送中',
        regain: '重新获取',
        curVersion: '当前版本',
        clearSuccess: '清除成功',
        homePage: '首页',
        release: '发布',
        my: '我的',
        revertText: '返回上一页',


        noData: '无数据',
        textInOffset: '下拉刷新', // 下拉的距离在offset范围内的提示文本
        textOutOffset: '释放更新', // 下拉的距离大于offset范围的提示文本
        textLoading: '加载中 ...', // 加载中的提示文本
        textSuccess: '加载成功', // 加载成功的文本
        textErr: '加载失败', // 加载失败的文本


        guessLike: '猜你喜欢',
        commonProblem: '常见问题',

        rentHouse: '租房/出租',

        // ------------未翻译的
        pleaseLogin: '请登录',
        toHome: '去首页',
        noLoginPleaseToLogin: '暂未登录请前去登录',
        confirmMakePhone: '确认拨打电话吗？',
        repeatText: '请勿重复提交',
    },
    noData: '暂无数据',
    curPhoneNum: '当前手机号',
    curEmailNum: '当前邮箱号',
    pleaseUploadPhoto: '请上传应用截图，最多上传6张',
    officialPhone: '官方电话',
    officialWx: '官方微信',
    officialEmail: '官方邮箱',
    officialWhatsAPP: 'WhatsApp',
    officialAddress: '官方地址',
    search: '搜索',
    reset: '重置',
    releaseContent: '发布内容',
    to: '至',
    submitTime: '提交时间',
    submitTime2: '提交时间',
    flowText: 'Caudal', //新加的流量字段
    auditTime:'审核时间',
    startTime: '开始日期',
    endTime: '结束日期',
    state: '状态',
    reason: '原因',
    operation: '操作',
    question:'问题内容',
    checkDetail: '查看详情',
    submitAgain: '重新提交',
    onlySee:'仅自己可见',
    shuiYin:'请选择添加的水印',
    chushou:'已出售',
    chuzu:'已出租',
    allSee:'所有人可见',
    addWatermark:'添加水印',
    basicInfo: '基本信息',
    contactInfo: '联络信息',
    detailAddress: '详细位置',
    InputDetailAddress:'请输入详细位置',
    deleteSelectList: '删除选中的内容',
    message: '消息',
    scanCodeToDownload: '扫码下载',
    uploadPhoto: '上传头像',
    myAlsoSay: '我也说一句',
    curState: '当前状态',
    modifyReleaseInfo: '修改发布信息',
    download: '下载App',
    pleaseUploadTheCorrectVideoFormat: '请上传正确的视频格式',
    theSizeOfUploadedVideoCannotExceed: '上传视频大小不能超过10MB',
    pleaseUploadTheCorrectImageType: '请上传正确的图片类型',



    // 增项
    publishingProtocol:'发布协议',
    releasePrice:'发布价格',
    totalPrice:'总计价格',
    infoEffectiveTime:'信息有效时长',
    remainingTime:'剩余时长',
    weiXinContact: '微信联系',
    messageContact: '短信联系',
    emailContact: '邮箱联系',
    email: '邮箱',
    inputEmail:'请填写邮箱',
    contactWXId: '联系人的微信号',
    copy: '复制',
    contactEmailId: '联系人的邮箱号',
    inputSendContent: '请输入发送内容',
    wechatID: '微信号',
    inputWechat: '请填写微信号',
    inputWhatsApp: '请填写WhatsApp',
    contacts: '联系人',
    inputContacts: '请填写联系人',
    contactsPhone:'联系电话',
    inputContactsPhone:'请填写联系电话',
    day:'天',
    fullAddress:'详细地址',
    telephone:'电话',
    mail:'邮件',
    wx:'微信',

    address: '所在地址',
    companyName: '公司名称',
    inputCompanyName: '请填写公司名称',
    companyTaxNumber:'公司税号',
    inputCompanyTaxNumber:'请填写公司税号',
    brokerLicenseNumber:'经纪人执照号',
    inputBrokerLicenseNumber:'请填写经纪人执照号',
    companyAddress:'公司地址',
    inputCompanyAddress:'请填写公司地址',
    agentType:'代理类型',
    inputAgentType:'请选择代理类型',
    businessHours:'营业时间',
    inputBusinessHours:'请填写营业时间',
    inputWeb:'请填写Web',
    inputSkype:'请填写Skype',
    inputFacebook:'请填写Facebook',
    inputTwitter:'请填写Twitter',
    inputInstagram:'请填写Instagram',
    inputLinkedin:'请填写Linkedin',

    consultationDetails:'咨询详情',
    broker:'专业人士',
    brokerParticulars:'专业人士详情',


    brand:'品牌',
    percent:'视频上传中',
    



    PersonalIntroduction:'个人介绍',
    inputPersonalIntroduction:'请填写个人介绍',
    IDNumber: '身份证',
    inputIdNumber: '请填写身份证号码',
    aboutCompany: '公司简介',
    pleaseEnterCompanyProfile: '请输入公司简介',
    copySuccess: '复制成功',
    email: '邮箱',
    mobile: '手机号',
    code: '邮箱验证码',
    completeInfor: "请完善个人信息",
    scanLogin: '扫码登录',
    sendSuccess: '发送成功',
    isStop: '是否停用',
    contactUs: '联系我们',
    techniqueFeedback: '技术反馈',
    business: '商业事宜',
    WechatNumber: '微信号',
    emailVerify: '邮箱验证',
    mobileVerify: '手机号验证',
    verify: '验证',
    importantTips: '重要提示：必须验证您的手机号码或电子邮件以进行发布信息。',
    interestAdvertise: '我对您的广告很有感兴趣，请您联系我！',
    advertiseLink: '广告链接',
    weChatScan: '微信扫一扫分享',
    talent: "人才招聘",
    complain: '投诉',

    retainedOwnership: '保留所有权利',
    privacyPolicy2: '《隐私政策》',
    userRegistrationAgreement2: '《用户注册协议》',
    companyLogo: 'Logo',
    uploadLogo: '上传Logo',
    contactCompany:'联系公司',

    Know:'我知道了',

    imgMessage:'最大上传2mb, jpg, gif，最佳尺寸2048px x 450px',
    videoMessage:'支持 MP4, WEBM, OGG 格式',


    //支付弹窗
    pay:{
        amountPayable:'应付金额',
        include:'包含',
        taxRate:'税费',
        payNow: '立即支付',
    },

    // 首页底部的弹窗
    dialog:{
        technicalSupport:'技术支持',

        ContactUs:'联系我们',
        UserForum:'用户论坛',
        AskForHelp:'请求帮助',
        news:'新闻',
        Sign:'注册',
        works:'在Encuentra24工作',
        Glossary:'最佳实践词汇表',

        LegalClause:'法律条款',
        
        SafetyCenter:'安全中心',
        PrivacyPolicy:'隐私政策',
        TermsOfService:'服务条款',
        expense:'费用',
        RefundPolicy:'退款政策',
        SafetyTips:'安全提示',

    },


    // 需要翻译的增项
    editRelease: '编辑发布',
    dateSort: '日期',
    lastTime: '最新时间',
    farTime: '最远时间',
    inforOrder: '信息订单',
    adOrder: '广告订单',
    vipOrder: '会员订单',
    order: {
        all: '全部',
        obligation: '待付款',
        finish: '已完成',
        orderNo: '订单编号',
        hasCancel: '已取消',
        accountPayable: '应付款',
        cancelOrder: '取消订单',
        toPay: '去付款',
        deleteOrder: '删除订单',
        orderDetail: '订单详情',
        orderToPay: '订单待付款',
        orderHasCancel: '订单已取消',
        orderHasFininsh: '订单已完成',
        youHasCancelOrder: '您已取消订单',
        restTime: '剩余时间',
        youHasPayOrder: '您已经成功支付订单，等待后台审核',
        messageFee: '信息费',
        informationDuration:'信息时长',
        locationHighlight: '位置凸显',
        locationRecommend: '位置推荐',
        orderInfor: '订单信息',
        orderTime: '下单时间',
        payMoney: '付款金额',
        payWay: '支付方式',
        payTime: '支付时间',
        adFee: '广告费',
        vipFee: '会员费',
        applyAd: "申请广告位",
        editAd: '编辑广告位',
        adPosition: "广告位置",
        adContent: "广告内容",
        adDuration: '广告时长',
        inputDuration: '请输入时长',
        photo: '图片',
        aSheet: '一张',
        toPay: '去支付',
        adIntroduce: '广告位说明',
        selectAdContent: '选择广告内容',
        pleaseSelectAdPosition: '请选择广告位置',
        pleaseSelectAdContent: '请选择广告内容',
        pleaseSelectAdDuration: '请输入广告天数',
    },
    myAd: '我的广告',
    myOrder: '我的订单',

    talentHire: '人才招聘',
    postType: '岗位类型',
    choosePostType:'请选择岗位类型',
    resumeUpload: '简历上传',
    yourResumeHasBeenSubmittedSuccessfully: '您的简历已提交成功',
    thePlatformStaffWillContactYouAfterAdmission: '录取后平台工作人员会与您取得联系',
    talentHireTitle: '平台保障您的信息不被泄露，请放心填写',
    day: '天',
    confirmDeleteCurrentOrder: '确定删除当前订单？',
    applyTime: '申请时间',
    thePlatformGuaranteesYourInformation: '平台保障您的信息不被泄露，请放心填写',
    name: '姓名',
    inputName: '请输入姓名',
    residentialAddress: '居住地址',
    inputResidentialAddress: '请输入居住地址',
    showStyle: '展示方式',
    positionTheLegislature: '位置推前',
    positionToHighlight: '位置凸显',
    ruleDescription: '规则说明',
    ruleDescriptionContent: '位置凸显，只是改变发布信息的底色，其他内容不变 位置推前是指热门推荐，付费后靠前展示，星号标记',
    endTime: '到期时间',
    pleaseUploadResume: '请上传简历',

    view:'查看',
    open:'开通',
    money: '金额',
    submitOrder: '提交订单',
    payOrder: '付款',
    finishOrder: '完成',
    vipName: '会员名称',
    atMostPhoto: '最多上传一张图片',
    clickUpload: '点击上传',
    onlyUploadPDFAndImg: '只能上传pdf和图片',
    uploadRightFileType: '请上传正确的文件类型',
    systemInformation: '系统信息',
    clickForDetails: '点击查看详情',
    chooseAreaCode:'请选择区号'
}