export default {
  name: 'App',
  components: {},
  data() {
    return {
      description: "",
      description2: ""
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [{
        vmid: "description",
        name: "description",
        content: this.description
      }, {
        vmid: "keywords",
        name: "keywords",
        content: this.description2
      }]
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.description = '巴拿马';
      this.description2 = '巴拿马二测';
      // keyWord({
      // 	siteId: window.localStorage.getItem("siteId"),
      // 	}).then((res) => {
      // 		console.log(res,'快快快');
      // 		if (res.code = 200) {
      // 			this.title = res.data.index_title;
      // 			this.description = res.data.desc;
      // 			this.description2 = res.data.keys;
      // 		}
      // });
    }
  }
};