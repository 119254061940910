import "core-js/modules/es.array.push.js";
import bus from '../common/function/bus.js';
export default {
  data() {
    return {
      classifyList: [],
      curClassifyList: [],
      //当前点击的分类数组
      bannerArr1: '',
      //
      bannerArr2: '' //
    };
  },

  mounted() {
    this.getCategoryList();
    this.getAds(1, 12);
    this.getAds(2, 13);
    window.addEventListener("setItemEvent", e => {
      if (e.key === "lang") {
        this.getCategoryList();
      }
    });
  },
  methods: {
    // 轮播图切换事件
    bannerArrChange(item) {
      console.log(item);
      let ads_type = item.ads_type;
      let bind_information_id = item.bind_information_id;
      let href = item.href;
      if (ads_type == 1) {
        if (href) {
          window.open(href);
        }
      } else {
        this.$router.push({
          path: '/home/detail',
          query: {
            information_id: bind_information_id
          }
        });
      }
    },
    // 获取轮播图
    getAds(type, typeid) {
      this.$http.getAds({
        typeid
      }).then(res => {
        if (res.code == 1) {
          switch (type) {
            case 1:
              if (res.data.length > 0) {
                this.bannerArr1 = res.data[0];
              }
              break;
            case 2:
              if (res.data.length > 0) {
                this.bannerArr2 = res.data[0];
              }
              break;
          }
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 去分类结果页
    toTypeResult(val) {
      console.log("分类分类", val);
      // if(!this.$store.state.userinfo){
      //    this.$message.error(this.$t('other.noLoginPleaseToLogin'));
      //     setTimeout(() => {
      // 		this.$router.push({
      // 			path: "/accountLogin"
      // 		});
      // 	}, 1500)  
      //    return false;
      // }
      // this.$router.go(0);
      this.$router.push({
        path: '/typeResult',
        query: {
          pId: val.pid,
          pName: val.pid_info.name,
          bnmName: val.pid_info.bnm_name,
          zhName: val.pid_info.name,
          id: val.id
          // pages:1,
          // cateId: val.id,
        }
      });

      this.$store.commit('changeShowFilter');
    },
    // 模态框点击
    showModalClick() {
      this.$store.commit('changeShowFilter');
    },
    getCategoryList() {
      console.log("ppp");
      this.$http.getCate({
        is_hot: 0
      }).then(res => {
        if (res.code == 1) {
          console.log("获取分类");
          console.log(res.data);
          this.classifyList = res.data;
          this.curClassifyList = res.data[0].children;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 标题点击
    titleClick(item) {
      this.curClassifyList = item.children;
    }
  }
};